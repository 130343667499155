import { useState, useRef } from "react"
import ReactPixel from "react-facebook-pixel"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { Base64 } from "js-base64"
import classNames from "classnames"
import {
  CoinbasePay,
  Button,
  Layout,
  Promo,
  SeatMapTevo,
  SeatMap,
} from "../components"
import SeatPreview from "../SeatPreview/SeatPreview"
import { clearSelectedEvent } from "../reducers/eventSlice"
import {
  clearSelectedTicketThumb,
  clearSelectedTicketGroup,
} from "../reducers/ticketSlice"

import "./CheckoutPage.css"
import styles from "./CheckoutPage.module.scss"
import "react-tooltip/dist/react-tooltip.css"
import { useEffect } from "react"
import { AngleLeft, Info, CheckCircle } from "../css/icons"
import { useWallet } from "@solana/wallet-adapter-react"
import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  PDA,
} from "@captainxyz/solana-core"
import { AuctionHouseAccount } from "@captainxyz/marketplace"
import postToSlack from "../postToSlack"
import { setUSDCBalance, setUser } from "../reducers/userSlice"
import { getTokenBalance } from "../helpers/getTokenAccounts"
import SignInModal from "../Authentication/SignInModal"
import SelectDeliveryMethod from "./SelectDeliveryMethod"
import PaymentMethodBox from "./PaymentMethodBox"
import StripeStuff from "./StripeStuff"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { Blurhash } from "react-blurhash"
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const {
  PublicKey,
  Connection,
  Transaction,
  VersionedTransaction,
} = require("@solana/web3.js")
const Base58 = require("base58")
const bs58 = require("bs58")
const { formatCents } = require("../helpers/money")

const CheckoutPage = () => {
  const query = new URLSearchParams(window.location.search)
  const _quantity = query.get("quantity")

  const [quantity, setQuantity] = useState(_quantity)
  const [loading, setLoading] = useState(false)
  const [buying, setBuying] = useState(false)
  const [price, setPrice] = useState(false)
  const [ticketGroup, setTicketGroup] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cc")
  const [showSigninModal, setShowSigninModal] = useState(false)
  const [coupon, setCoupon] = useState(null)
  const [deliveryMethod, setDeliveryMethod] = useState("instant")
  const [seatsSelected, setSeatsSelected] = useState(false)
  const [stripeClientSecret, setStripeClientSecret] = useState(null)
  const [paymentIntentId, setPaymentIntentId] = useState(null)
  const [showApplePay, setShowApplePay] = useState(null)
  const [applePayLoading, setApplePayLoading] = useState(null)

  const stripeRef = useRef()
  const applePayRef = useRef()

  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const adapter = useWallet()


  const user = useSelector((state) => state.user.user)
  const usdcBalance = useSelector((state) => state.user.usdc)

  // TODO verify quantity is valid
  const ticket_group_vendor_id = params.ticket_group_vendor_id
  const event_id = params.event_id

  useEffect(() => {
    checkForApplePay()
    window.scrollTo(0, 0)
    getTicketGroup()
    getEvent()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ticketGroup) {
      checkIfTicketIsAvailable()
    }
  }, [ticketGroup]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ticketGroup && quantity) {
      setPrice(ticketGroup.price * quantity)
    }
  }, [ticketGroup, quantity]) // e

  useEffect(() => {
    if (price && paymentIntentId) {
      updatePaymentIntent()
    }
  }, [price, paymentIntentId]) // e

  useEffect(() => {
    if (
      location.pathname.includes("payment") &&
      ticketGroup &&
      (user?.user?.email || user?.email)
    ) {
      getPaymentIntentId()
    }
  }, [location.pathname, user, ticketGroup]) // e

  useEffect(() => {
    return () => {
      // get rid of these when you leave this screen
      dispatch(clearSelectedEvent())
      dispatch(clearSelectedTicketThumb())
      dispatch(clearSelectedTicketGroup())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // mark them authenticated and also note in local storage
    // that they're in a campaign so that if they navigate away
    // they will be reauthenticated
    localStorage.setItem("phantom_browser", "yes")
    if (user && !user.authenticated) {
      let u2 = JSON.parse(JSON.stringify(user))
      u2.authenticated = true
      dispatch(setUser(u2))
    }

    if (user?.publicKey && usdcBalance === null) {
      getUsdcBalance()
    }

    if (user?.loginMethod === "phone" && selectedPaymentMethod === null) {
      setSelectedPaymentMethod("cc")
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      window.checkedForCoupon = false
      window.updatedPaymentIntentLog = false
      window.checkedIfAvailable = false
      window.GETTING_PAYMENT_INTENT_ID = null
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      user?.loginMethod !== "phone" &&
      usdcBalance !== null &&
      ticketGroup &&
      !selectedPaymentMethod
    ) {
      if (
        usdcBalance > (ticketGroup.price / 100) * quantity &&
        !selectedPaymentMethod
      ) {
        setSelectedPaymentMethod("usdc")
      } else {
        setSelectedPaymentMethod("cc")
      }
    }
  }, [user, usdcBalance, ticketGroup]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkForApplePay = async () => {
    if (window.ApplePaySession) {
      if(window.ApplePaySession.canMakePaymentsWithActiveCard("xp.xyz")){
        setShowApplePay(true)
        setSelectedPaymentMethod("applepay")
      } else{
        setShowApplePay(false)
      }
    } else {
      setShowApplePay(false)
    }
  }

  const updatePaymentIntent = async () => {
    let total = calculateTotal(coupon)
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      total: total,
      payment_intent_id: paymentIntentId,
    }
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/update-payment-intent-quantity`

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
    if(applePayRef.current){
      setApplePayLoading(true)
      await applePayRef.current.update()
      setApplePayLoading(false)
    }

    resp = await resp.json()
  }

  const getTicketGroup = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/live-ticketgroup-by-id`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      ticket_group_vendor_id: ticket_group_vendor_id,
      event_id: event_id,
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    resp = await resp.json()
    if (!resp.ticket_group) {
      alert("This ticket is no longer available")
      navigate(`/event/${params.event_id}`)
    }
    setTicketGroup(resp.ticket_group)
  }

  const getEvent = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/event`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      event_id: event_id,
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    resp = await resp.json()
    setSelectedEvent(resp)
  }

  const getPaymentIntentId = async () => {
    if (window.GETTING_PAYMENT_INTENT_ID) return
    if (paymentIntentId) return

    window.GETTING_PAYMENT_INTENT_ID = true

    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/create-payment-intent`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      total: ticketGroup.price * quantity,
      wallet: user.publicKey,
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    resp = await resp.json()
    setPaymentIntentId(resp.payment_intent_id)
    setStripeClientSecret(resp.client_secret)
  }

  const sendReceiptNFT = async (uuid) => {
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/receiptMint`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      uuid: uuid,
    }
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
  }

  const transferNftAfterCreditCard = async () => {
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/xferStripeNFT`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      wallet: user.publicKey.toString(),
      payment_intent_id: paymentIntentId,
      deliveryMethod: deliveryMethod,
      coupon_mint: coupon?.mint.address.toString(),
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })

      resp = await resp.json()
      return resp.success
    } catch (err) {
      return false
    }
  }

  const checkIfTicketIsAvailable = async () => {
    //TODO dont return
    return

    if (ticketGroup?.vendor !== "tevo") return
    if (window.checkedIfAvailable) return

    window.checkedIfAvailable = true
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/ticket-availability`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      ticket_group_id: ticketGroup.id,
      quantity: quantity,
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })

    resp = await resp.json()
    if (!resp.available) {
      alert("This ticket is no longer available")
      navigate(-1)
    }
  }

  const getUsdcBalance = async () => {
    let balance = await getTokenBalance(
      user,
      "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
    )
    dispatch(setUSDCBalance(balance))
  }

  const pollUsdcBalance = async () => {
    getUsdcBalance()
    setTimeout(() => pollUsdcBalance(), 2000)
  }

  const recordBought = async (address, apple_pay) => {
    let url = `${process.env.REACT_APP_HNGR_API}/stagehand/bought/v2`
    let headers = {
      "Content-Type": "application/json",
    }

    let utm_source, utm_medium, utm_campaign

    try {
      utm_medium = await localStorage.getItem("utm_medium")
      utm_source = await localStorage.getItem("utm_source")
      utm_campaign = await localStorage.getItem("utm_campaign")
    } catch (err) {
      console.log("error getting utms", err)
    }

    // TODO: backend handle nft_uuid
    let parameters = {
      quantity: quantity,
      event_id: params.event_id,
      sold_to: address,
      delivery_method: deliveryMethod,
      email: user?.user?.email || user?.email,
      first_name: user?.user?.first_name || user?.first_name,
      last_name: user?.user?.last_name || user?.last_name,
      coupon_mint: coupon?.mint.address.toString(),
      currency: selectedPaymentMethod,
      promo: calculateDiscount(coupon),
      ticket_group_vendor_id: ticketGroup.vendor_id,
      payment_intent_id: paymentIntentId,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      apple_pay:apple_pay
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(parameters),
      })

      resp = await resp.json()
      if (resp.success) {
        try {
          await localStorage.removeItem("utm_medium")
          await localStorage.removeItem("utm_source")
          await localStorage.removeItem("utm_campaign")
        } catch (err) {
          console.log("Error unsetting utms", err)
        }
        return resp.uuid
      } else {
        alert(
          "An unknown error occurred. If the problem persists, please reach out to support@xp.xyz."
        )
        navigate("/")
      }
      return false
    } catch (err) {
      console.log("checkout error", err)
      alert(
        "An unknown error occurred. If the problem persists, please reach out to support@xp.xyz."
      )
      navigate("/")
      return false
    }
  }

  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2")
  }

  const checkoutWithUSDC = async () => {
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/buyTicketWithUSDC`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      wallet: user.publicKey.toString(),
      deliveryMethod: deliveryMethod,
      couponMint: coupon?.mint.address.toString(),
      event_id: event_id,
      ticket_group_vendor_id: ticketGroup.vendor_id,
      quantity: quantity,
    }

    if (user?.loginMethod === "phone") {
      // TODO implement
      return
    } else {
      try {
        let resp = await fetch(url, {
          method: "post",
          headers: headers,
          body: JSON.stringify(params),
        })

        const connection = new Connection(process.env.REACT_APP_RPC) // eslint-disable-line no-unused-vars
        const {
          context: { slot: minContextSlot },
          value: { blockhash, lastValidBlockHeight },
        } = await connection.getLatestBlockhashAndContext()

        const data = await resp.json()
        const txEncoded = data.txn
        const txn = Transaction.from(Base64.toUint8Array(txEncoded))
        const signature = await adapter.sendTransaction(txn, connection, {
          minContextSlot,
        })
        let result = await connection.confirmTransaction(
          {
            blockhash,
            lastValidBlockHeight,
            signature,
          },
          "confirmed"
        )
        console.log("result is", result)

        if (result.value?.err) {
          alert("Buying failed")
          postToSlack("Transaction failed usdc " + signature, "firehose", user)
          return false
        } else if (result) {
          return true
        } else {
          // TODO be smarter here
          alert("Buying may have failed. Check your wallet")
        }
      } catch (err) {
        alert("Unknown error")
        return false
      }
    }
  }

  const sleep = (time) =>
    new Promise((res) => setTimeout(res, time, "done sleeping"))

  const captureStripePayment = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/complete-stripe-purchase/v2`
    let headers = {
      "Content-Type": "application/json",
    }

    let parameters = {
      event_id: params.event_id,
      total: calculateTotal(coupon),
      ticket_group_vendor_id: ticketGroup.vendor_id,
      quantity: quantity,
      payment_intent_id: paymentIntentId,
      coupon_mint: coupon?.mint.address.toString(),
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(parameters),
    })

    return true
  }

  const checkoutWithStripe = async () => {
    if (calculateTotal(coupon) === 0) return true

    let success = await stripeRef.current.submitPayment()
    if (success) {
      try {
        let success = await captureStripePayment()
        if (!success) {
          postToSlack("Fail capturing stripe stripe", "firehose", user)
          return false
          alert("Payment error. Please refresh and try again.")
        }
      } catch (err) {
        console.log("capturing stripe error", err)
        postToSlack("Crash capturing stripe <@U1210FPAL>", "firehose", user)
        alert("Payment error. Please refresh and try again.")
        return false
      }
      return true
    } else {
      setBuying(false)
    }
  }

  const checkoutWithGoogleApplePay = async (express) => {
    if (calculateTotal(coupon) === 0) return true
    try {
      let success = await captureStripePayment()
      if (!success) {
        postToSlack("Fail capturing stripe stripe", "firehose", user)
        return false
        alert("Payment error. Please refresh and try again.")
      }
    } catch (err) {
      console.log("capturing stripe error", err)
      postToSlack("Crash capturing apple pay/google pay stripe <@U1210FPAL>", "firehose", user)
      alert("Payment error. Please refresh and try again.")
      return false
    }
      return true
  }


  const verifyTicketPrice = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/live-ticketgroup-by-id`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      ticket_group_vendor_id: ticket_group_vendor_id,
      event_id: event_id
    }

    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    let tg = resp.ticket_group

    if(!tg ){
      alert('This ticket is no longer available')
      postToSlack(
        `Tried to purchase but the ticket group is no longer available for ${selectedEvent?.title}`,
        "firehose",
        user
      )
      navigate(`/event/${params.event_id}`)
      return false
    }

    if (tg.price !== ticketGroup.price ) {
      let msg = `The ticket price has changed to ${formatCents(tg.price)}`
      alert(msg)
      postToSlack(
        `Tried to check out on ${selectedEvent?.title} but the ticket price has changed.`,
        "firehose",
        user
      )
      setTicketGroup(tg)
      setBuying(false)
      return false
    } else {
      return true
    }
  }



  const cancelApplePayPaymentIntentAndGetNewPaymentIntent = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/cancel-apple-pay-payment-intent`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      payment_intent_id: paymentIntentId,
      wallet: user.publicKey.toString(),
      total: calculateTotal(coupon),
    }
    setPaymentIntentId(null)
    let resp = await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    return true
  }

  const checkout = async (express) => {
    // verify they are logged in and have an email set
    let email = user?.user?.email || user?.email
    if (!email) {
      postToSlack(
        "logged in, but email isn't set when trying to purhcase tickets, bumping them back a page ... <@U1210FPAL> <@U01BNA4G4G3>",
        "firehose",
        user
      )
      alert("Please enter your email.")
      navigate(
        `/checkout/${params.event_id}/${params.ticket_group_vendor_id}/delivery?quantity=${quantity}`
      )
      return
    }


    if(!(express || selectedPaymentMethod === 'applepay')) {
      postToSlack(
        `Clicked 'Purchase Tickets' for ${selectedEvent?.title}`,
        "firehose",
        user
      )
    }


    setBuying(true)
    let priceStillLegit = await verifyTicketPrice()
    if (!priceStillLegit) {
      if(express || selectedPaymentMethod === 'applepay') {
        await cancelApplePayPaymentIntentAndGetNewPaymentIntent()
        window.location.reload()
      }
      return
    }

    let success
    if(express || selectedPaymentMethod === 'applepay') {
      success = await checkoutWithGoogleApplePay()
    } else if (selectedPaymentMethod == "cc") {
      success = await checkoutWithStripe()
    } else {
      success = await checkoutWithUSDC()
    }

    if (success) {
      let uuid = await recordBought(user.publicKey.toString(), express)
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: uuid,
          value: calculateTotal(coupon) / 100,
          currency: "USD",
          items: [
            {
              event_id: selectedEvent?.event_id,
              item_name: selectedEvent?.performer_name,
              item_id: ticketGroup?.id,
              price: price / 100 / quantity,
              quantity: quantity,
              discount: calculateDiscount(coupon) / 100,
              coupon_pk: coupon?.pk,
            },
          ],
        },
      })

      if (user?.loginMethod != "phone") {
        sendReceiptNFT(uuid)
      }

      // if it's phantom, send them a receipt

      if (!uuid) {
        postToSlack(
          `Had trouble buying and probably needs customer support. ${selectedEvent?.title}`,
          "firehose",
          user
        )
        return
      }
      navigate(`/holder/ticket/${uuid}?success=true&quantity=${quantity}`)
      ReactPixel.track("Purchase", {
        user: user?.publicKey?.toString(),
        ticketGroup: ticketGroup?.id,
        value: price / 100,
        currency: "USD",
      })
    }
    setBuying(false)
  }

  // note we are passing in coupon_ manually and not using the state one
  const calculateDiscount = (coupon) => {
    if (!coupon) return 0
    if (coupon) {
      let discountType = coupon.metadata._json.discount_type
      let discount
      if (discountType === "percent") {
        discount =
          coupon.metadata._json.discount * quantity * ticketGroup?.price
        
        if (coupon.metadata._json.max_discount) {
          discount = Math.min(
            discount,
            coupon.metadata._json.max_discount
          )
        }

      }

      if (discountType === "flat") {
        discount = coupon.metadata._json.discount
      }
      discount = Math.min(discount, price)

      if(coupon.metadata._json.minimum_spend){
        if(price - discount < coupon.metadata._json.minimum_spend){
          discount = price - coupon.metadata._json.minimum_spend
        }
      }
      return discount
    }
  }

  // note we are passing in coupon_ manually and not using the state one
  const calculateTotal = (coupon_) => {
    let subtotal = price
    let discount = calculateDiscount(coupon_)
    let total = subtotal - discount
    return total
  }

  const stripeOptions = () => {
    if (!stripeClientSecret) return {}
    return {
      clientSecret: stripeClientSecret,
    }
  }

  const removeCoupon = async () => {
    setCoupon(null)
    setLoading(true)

    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/remove-coupon-payment-intent`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      total: calculateTotal(),
      payment_intent_id: paymentIntentId,
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      if(applePayRef.current){
        setApplePayLoading(true)
        await applePayRef.current.update()
        setApplePayLoading(false)
      }
      setLoading(false)
    } catch (err) {
      alert("Unknown error applying coupon")
      setCoupon(null)
      setLoading(false)
    }
  }

  const applyCoupon = async (coupon) => {
    setCoupon(coupon)
    setLoading(true)

    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/apply-coupon-payment-intent`
    let headers = {
      "Content-Type": "application/json",
    }

    let params = {
      total: calculateTotal(coupon),
      payment_intent_id: paymentIntentId,
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      if(applePayRef.current){
        setApplePayLoading(true)
        await applePayRef.current.update()
        setApplePayLoading(false)
      }
      setLoading(false)
      return true
    } catch (err) {
      alert("Unknown error applying coupon")
      console.log("error applying coupon", err)
      setCoupon(null)
      setLoading(false)
      return false
    }
  }
  

  const renderCheckout = () => {
    return (
      <div className={classNames(styles.container)}>
        {calculateTotal(coupon) === 0 ? (
          <div> </div>
        ) : (
          <>
            <div className={styles.paymentMethodBoxRow}>
              <PaymentMethodBox
                type="cc"
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />
              <PaymentMethodBox
                disabled={user?.loginMethod === "phone"}
                type="usdc"
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />
              {showApplePay && (
                <PaymentMethodBox
                  type="applepay"
                  selectedPaymentMethod={selectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                />
              )}
            </div>
            {selectedPaymentMethod === "cc" && paymentIntentId && (
              <div className={styles.stripeContainer}>
                <Elements stripe={stripePromise} options={stripeOptions()}>
                  {selectedPaymentMethod === "cc" && (
                    <StripeStuff
                      ref={stripeRef}
                      clientSecret={stripeClientSecret}
                    />
                  )}
                </Elements>
              </div>
            )}
            {selectedPaymentMethod === "usdc" && (
              <>
                <div className={styles.sufficientUSDC}>
                  <div className={styles.walletInfo}>
                    <span>My USDC Balance</span>
                    <span className={styles.walletBalance}>${usdcBalance}</span>
                  </div>
                  {usdcBalance < (quantity * ticketGroup?.price) / 100 ? (
                    <CoinbasePay
                      onClick={() => pollUsdcBalance()}
                      price={quantity * ticketGroup?.price}
                    />
                  ) : (
                    <div className={styles.saveWithUSDC}>
                      <div className={styles.saveWithUSDCContent}>
                        {/* <div className={styles.saveWithUSDCHeader}>
                          3% off with USDC
                        </div> */}
                        <div className={styles.saveWithUSDCSubheader}>
                          Your USDC balance covers this purchase
                        </div>
                      </div>
                      <CheckCircle />
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}

        {/* total/price box thing */}
        <div className={styles.buttonContainer}>
          {(selectedPaymentMethod !== 'applepay'  || buying || calculateTotal(coupon) == 0)  ? (
            <Button
              className={styles.checkoutButton}
              loading={loading || buying}
              fullWidth
              onClick={() => {
                if (!user?.publicKey) {
                  postToSlack(
                    "is not logged in while trying to purhcase tickets, showing modal... <@U1210FPAL> <@U01BNA4G4G3>",
                    "firehose",
                    user
                  )
                  setShowSigninModal(true)
                } else {
                  checkout()
                }
              }}
            >
              Purchase Tickets
            </Button>
          ) : (
            <>
            {!!paymentIntentId && !applePayLoading && (
               <Elements   stripe={stripePromise} options={stripeOptions()}>
                  <StripeStuff
                    ref={applePayRef} 
                    applePay={true}
                    selectedEvent={selectedEvent}
                    expressSuccess={()=>checkout(true)}
                    clientSecret={stripeClientSecret}
                  />
                </Elements>
            )}
            </>
          )}
        </div>
      </div>
    )
  }

  // the user has not yet minted the ticket
  // can be logged in or not, will be prompted to log in before continuing
  // this is its own view because the sidebar is different, all others here share
  // one sidebar
  if (location.pathname.includes("tickets")) {
    return (
      <>
        {!!ticketGroup && (
          <SeatPreview
            ticketGroup={ticketGroup}
            updateQuantity={(qty) => {
              let base = location.pathname
              navigate(`${base}?quantity=${qty}`, { replace: true })
              setQuantity(qty)
            }}
            onClick={(q) => {
              postToSlack(
                `has selected seats for <https://xp.xyz/event/${selectedEvent.event_id}|${selectedEvent.title}>`,
                "firehose",
                user
              )
              setSeatsSelected(true)
              if (!user?.publicKey) {
                setShowSigninModal(true)
              } else {
                navigate(
                  `/checkout/${params.event_id}/${params.ticket_group_vendor_id}/delivery?quantity=${quantity}`
                )
              }
            }}
          />
        )}

        {showSigninModal && (
          <SignInModal
            onClose={() => {
              setShowSigninModal(false)
            }}
          />
        )}
      </>
    )
  }

  let mapVendor, mapConfig
  if (selectedEvent?.seatmap_id_3ddv) {
    mapVendor = "3ddv"
    mapConfig = {
      venueId: selectedEvent.seatmap_id_3ddv,
    }
  } else if (
    selectedEvent?.tevo_venue_id &&
    selectedEvent?.tevo_venue_config_id
  ) {
    mapVendor = "tevo"
    mapConfig = {
      venueId: selectedEvent?.tevo_venue_id,
      configurationId: selectedEvent?.tevo_venue_config_id,
    }
  }
  
  return (
    <Layout
      className={styles.topLevelContainer}
      contentClassName={styles.layoutContainer}
      noScroll
      showHeader={window.innerWidth > 960}
    >
      <div className={styles.blurhashContainer}>
        {selectedEvent?.image_blurhash && (
          <Blurhash
            hash={selectedEvent?.image_blurhash}
            width={window.innerWidth}
            height={window.innerHeight}
            resolutionX={32}
            resolutionY={32}
            punch={0}
          />
        )}
        <div className={styles.blurhashOverlay} />
      </div>
      <div className={styles.pageContainerOuter}>
        <div className={styles.leftColumn}>
          <div className={styles.back} onClick={() => navigate(-1)}>
            <AngleLeft />
            <span>Back</span>
          </div>
          <div className={styles.showInfo}>
            <picture>
              <source srcset={selectedEvent?.image_avif} type="image/avif" />
              <source srcset={selectedEvent?.image_webp} type="image/webp" />
              <img
                src={
                  selectedEvent?.image
                    ? selectedEvent.image
                    : "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png"
                }
                alt={selectedEvent?.title}
              />
            </picture>

            <div className={styles.showInfoHeader}>
              <div className={styles.showInfoContent}>
                <div className={styles.date}>
                  {selectedEvent?.date_formatted
                    ?.replace(",", " |")
                    .replace("•", " |")}
                </div>
                <div className={styles.title}>
                  {selectedEvent?.short_title || selectedEvent?.title}
                </div>
                <div className={styles.venue}>
                  {selectedEvent?.venue_name}, {selectedEvent?.venue_city},{" "}
                  {selectedEvent?.venue_state}
                  <Info />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.seatInfoContainer}>
            <div className={styles.seatInfo}>
              <div className={styles.section}>
                Section
                <span className={styles.value}>
                  {ticketGroup?.section?.name}
                </span>
              </div>
              <div className={styles.divider} />

              <div className={styles.row}>
                Row
                <span className={styles.value}>{ticketGroup?.row?.name}</span>
              </div>
            </div>
            <div className={styles.ticketInfoRight}>
              <div className={styles.ticketPrice}>
                <span>${(ticketGroup?.price / 100).toFixed(2)}</span>
                ea.
              </div>
            </div>
          </div>
          {deliveryMethod && price && location.pathname.includes("payment") && (
            <>
              <Promo
                total={price}
                removeCoupon={() => {
                  removeCoupon()
                  // todo remove coupon function for payment intent id
                }}
                setCoupon={applyCoupon}
              />
              <div
                className={classNames(
                  styles.cardBottom,
                  !coupon && styles.noCoupon
                )}
              >
                <div className={styles.cardContent}>
                  <div className={styles.cardTitle}>Subtotal</div>
                  <div className={styles.subtotal}>
                    {quantity} x ${(ticketGroup?.price / 100).toFixed(2)}
                  </div>
                </div>
                {coupon && (
                  <div className={styles.cardContent}>
                    <div
                      className={classNames(styles.cardTitle, styles.discount)}
                    >
                      Discount
                    </div>
                    <div className={styles.discount}>
                      -{coupon && formatCents(calculateDiscount(coupon))}
                    </div>
                  </div>
                )}
                <div className={styles.cardContent}>
                  <div className={classNames(styles.cardTitle, styles.total)}>
                    Total
                  </div>
                  <div className={styles.total}>
                    {formatCents(calculateTotal(coupon))}{" "}
                  </div>
                </div>
                <div className={styles.taxDisclaimer}>
                  Sales Tax and Applicable Fees included in Total
                </div>
              </div>
              {renderCheckout()}
            </>
          )}
          {location.pathname.includes("delivery") && (
            <SelectDeliveryMethod
              quantity={quantity}
              setDeliveryMethod={setDeliveryMethod}
            />
          )}
        </div>
        {/* end left sidebar */}
        <div className={styles.checkoutContainer}>
          <div className={styles.seatmap}>
            {mapVendor === "3ddv" && ticketGroup && (
              <SeatMap
                config={mapConfig}
                ticketGroups={[ticketGroup]}
                zoomedTicketGroup={ticketGroup}
                highlightedSections={[ticketGroup.section.name]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
                translationRules={selectedEvent?.translations_3ddv}
              />
            )}
            {mapVendor === "tevo" && ticketGroup && (
              <SeatMapTevo
                config={mapConfig}
                ticketGroups={[ticketGroup]}
                zoomedTicketGroup={ticketGroup}
                highlightedSections={[]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
                translationRules={selectedEvent?.translations_3ddv}
              />
            )}
          </div>
        </div>
      </div>
      {showSigninModal && (
        <SignInModal
          onClose={() => {
            setShowSigninModal(false)
          }}
        />
      )}
    </Layout>
  )
}

export default CheckoutPage
